import { createStore } from 'vuex'

const store =  createStore({
  state: {
    Authenticated: false,
    token: '',
     baseUrlAPi: window.appConfig.API_URL,
     baseUrlAPi2:window.appConfig.API_URL2,
/* baseUrlAPi: 'http://46.102.130.145/aos/development/public/mng/',
 baseUrlAPi2: 'http://46.102.130.145/aos/development/public/',*/
mobileNumber:'',
},
getters: {
isAuthenticated(state) {
  return state.Authenticated
},
getMobile(state){
  return state.mobileNumber
},
baseUrl(state) {
  return state.baseUrlAPi
},
baseUrl2(state) {
  return state.baseUrlAPi2
},
},
mutations: {
setLogin(state, token) {
  state.Authenticated = true
  state.token = token

},
setMobile(state,mobile){
  state.mobileNumber = mobile
}
},
actions: {
logout({commit}, newVal) {
  commit('setLogout', newVal);
},
saveLogin({commit}, newVal) {
  commit('setLogin', newVal);
},
setMobile({commit}, newVal) {
  commit('setProfile', newVal);
},
},
modules: {
}
})

export default store;
