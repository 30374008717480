<template>
  <div class="main-authentication">
    <div class="login-box box-auth">

      <img src="@/assets/images/logo.png" alt="logo">

      <h2>ورود</h2>
      <span>لطفا شماره موبایل خود را وارد کنید.</span>
      <div class="input" @keydown.enter="getMobile">
        <label>
          شماره موبایل
        </label>
        <input type="text" placeholder="0912 1234567" v-model="mobile">
      </div>
      <v-btn @click="getMobile" :loading="loading" class="btn btn-save">ورود</v-btn>

    </div>
    <v-snackbar
        :timeout="3000"
        v-model="snackbar"
        :color="colorSnack"
    >
      {{ textSnack }}

      <template v-slot:actions>
        <v-btn

            variant="tonal"
            @click="snackbar = false"
        >
          بستن
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>


import axios from "axios";
import store from "@/store";

export default {
  name: "LoginComponent",
  data() {
    return {
      apiUrl: store.getters.baseUrl,
      mobile:'',
      loading:false,
      snackbar: false,
      textSnack: '',
      colorSnack: '',
    };
  },
  methods: {
    getMobile(){
      this.loading = true
      axios.get(this.apiUrl+'login/mobileverify/'+this.mobile)
      .then(res =>{
        this.loading = false
        if(res.data.result === 1){
          this.$router.push('/auth/password')
          store.commit('setMobile',this.mobile)
        }else{
          this.showMsg('چنین کاربری یافت نشد', 'red')
        }
      })

    },
    showMsg(msg, color) {
      this.snackbar = true
      this.textSnack = msg
      this.colorSnack = color
    },
  },
};
</script>

<style scoped lang="scss">




</style>
