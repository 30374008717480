<template>
<LayoutComponent></LayoutComponent>
  <!-- <LayoutManagementComponent></LayoutManagementComponent>-->
</template>

<style lang="scss">
#app {
 width: 100%;
}


</style>
<script>
import LayoutComponent from "@/components/admin/Layout";
/*import LayoutManagementComponent from "@/components/management/Layout";*/
export default {
  components: {LayoutComponent}
/*  components: {LayoutManagementComponent}*/
}
</script>
