import { createApp } from 'vue'
import './assets/css/style.scss'
import 'vuetify/styles'

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'mdi/css/materialdesignicons.css'
const vuetify = createVuetify({
    components,
    directives,
})

import App from './App.vue'
import router from './router'
import store from './store'

createApp(App).use(store).use(vuetify).use(router).mount('#app')
