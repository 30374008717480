<template>
  <div class="main-authentication">
    <div class="login-box box-auth">

      <img src="@/assets/images/logo.png" alt="logo">

      <h2>رمز عبور را وارد کنید
      </h2>
      <div class="input input-pass " :class="{'error':error}">
        <label>
          گذرواژه
        </label>
        <input :type="showPass ? 'text':'password'" v-model="password">
        <i @click="showPass = !showPass" class="fa-regular"
           :class=" !showPass ? 'fa-eye-slash' : 'fa-eye'"
        ></i>
      </div>
      <div v-if="error" class="error-box text-danger">گذرواژه وارد شده اشتباه است.</div>
      <div class="forget-link">
        <router-link to="/auth/forget-password">فراموشی رمز عبور</router-link>
      </div>
      <div class="input captcha mt-0">
        <label>
          کد کپچا
        </label>
        <input type="text" v-model="captcha"/>
        <img @click="getCaptcha" :src="captchaImg" alt="">

      </div>
      <v-btn @click="submit" :loading="loading" class="btn btn-save">تایید</v-btn>
      <div class="separator"></div>
      <router-link class="btn disposable" to="/auth/otp"> ورود با رمز یکبار مصرف <i class="fa fa-angle-left"></i>
      </router-link>

    </div>
    <v-snackbar
        :timeout="3000"
        v-model="snackbar"
        :color="colorSnack"
    >
      {{ textSnack }}

      <template v-slot:actions>
        <v-btn

            variant="tonal"
            @click="snackbar = false"
        >
          بستن
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>


import axios from "axios";
import store from "@/store";
import Cookies from "js-cookie";

export default {
  name: "PasswordComponent",
  data() {
    return {
      apiUrl: store.getters.baseUrl,
      apiUrl2: store.getters.baseUrl2,
      showPass: true,
      captchaImg: '',
      error: false,
      captcha: '',
      password: '',
      mobile: '',
      loading:false,
      snackbar: false,
      textSnack: '',
      colorSnack: '',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    };
  },
  methods: {
    getCaptcha() {
      this.mobile = store.getters.getMobile
      if (store.getters.getMobile === '') {
        this.$router.push('/auth/login')
      }
      axios.get(this.apiUrl2 + 'captcha.php?direct=1&username='+this.mobile, {headers: this.headers})
          .then(res => {
            this.captchaImg = 'data:image/png;base64,' + res.data
          })

    },
    setCookie(token) {
      Cookies.set('token', token, {expires: 7, path: '/'});
    },
    submit() {

      this.loading = true

      let body = {
        api: 1,
        username: this.mobile,
        password: this.password,
        captcha_val: this.captcha.toLowerCase(),
      }
      axios.post(this.apiUrl + 'login/formpro', body, {headers: this.headers})
          .then(res => {
            this.loading = false
            if (res.data.result === 1) {
              let token = res.data.token
              this.setCookie(token)
              this.$router.push('/')
            }else{
              this.showMsg('رمز عبور اشتباه است', 'red')
            }

          })
    },
    showMsg(msg, color) {
      this.snackbar = true
      this.textSnack = msg
      this.colorSnack = color
    },

  },

  mounted() {
    this.getCaptcha()
  }
};
</script>

<style scoped lang="scss">

.main-authentication {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .separator {
    width: 150px;
    height: 1px;
    background: #EDEEF1;
    margin: 20px auto;
  }

  .forget-link {
    text-align: left;

    a {
      color: #3300CC;
    }
  }

  .box-auth {
    max-width: 440px;
    width: 100%;
    padding: 2rem 20px;
    border-radius: 32px;
    background: #fff;
    box-shadow: 0 0 10px #ccc;
  }

  .input-pass {
    position: relative;

    i {
      position: absolute;
      letter-spacing: 10px;
      bottom: 27px;
      left: 25px;
      cursor: pointer;
    }
  }

  .login-box {
    text-align: center;

    > img {
      margin: 20px auto 3rem;
    }

    h2 {
      text-align: right;
      font-size: 24px;
      margin-bottom: 10px;
    }

    span {
      font-size: 14px;
      text-align: right;
      color: #1F2937;
      width: 100%;
      display: block;
    }

    .input {
      width: 100%;
      max-width: none;
      margin-top: 15px;
      padding: 10px 0;

      input {
        direction: rtl;
      }

      label {
        text-align: right;
        font-size: 16px;
      }

      &.captcha {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        input {
          width: calc(100% - 140px);
        }

        img {
          width: 130px;
          object-fit: contain;
          margin: 0 10px 0 0;
          cursor: pointer;
        }
      }
    }

    .btn {
      background: #24272D;
      width: 100%;
      border-radius: 99px;
      margin-top: 20px;
      color: #fff;
      padding: 10px;
      font-size: 14px;

      &.disposable {
        background: transparent;
        border: 1px solid #24272D;
        color: #24272D;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          margin-right: 10px;
        }
      }
    }
  }


}


</style>
