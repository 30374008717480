<template>
  <div class="main-authentication">
    <div class="login-box box-auth">

      <img src="@/assets/images/logo.png" alt="logo">

     <div class="title">
       <router-link to="/auth/login"><i class="fa-regular fa-arrow-right"></i> بازگشت</router-link>

       <h2>انتخاب رمز جدید</h2>
     </div>
      <div class="input input-pass error">
        <label>
          گذرواژه جدید
        </label>
        <input :type="showPass ? 'text':'password'">
        <i  @click="showPass = !showPass" class="fa-regular"
            :class=" !showPass ? 'fa-eye-slash' : 'fa-eye'"
        ></i>


      </div>
      <div class="error-box text-danger">گذرواژه وارد شده اشتباه است.</div>
      <div class="input input-pass ">
        <label>
          تکرار گذرواژه جدید
        </label>
        <input :type="showPass2 ? 'text':'password'">
        <i  @click="showPass2 = !showPass2" class="fa-regular"
            :class=" !showPass2 ? 'fa-eye-slash' : 'fa-eye'"
        ></i>


      </div>

      <button class="btn btn-save">تکمیل</button>

    </div>
  </div>
</template>

<script>
export default {
  name: "NewPassword",
  data(){
    return{
      showPass:true,
      showPass2:true,
    }
  }
}
</script>

<style scoped>

</style>
