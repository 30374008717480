<template>
  <div class="main-authentication">
    <div class="login-box box-auth">
      <div class="login-box">

        <img src="@/assets/images/logo.png" alt="">


        <div class="form-box text-center">
          <div class="title">
            <router-link to="/auth/login"><i class="fa-regular fa-arrow-right"></i> بازگشت</router-link>

            <h2 class="w-100">کد تایید را وارد کنید</h2>
          </div>


          <div class="input">
            <label>کد تایید ارسال شده به شماره خود را وارد کنید:</label>
            <div class="input-group-cus">
              <input
                  v-for="(value, index) in inputs"
                  :key="index"
                  v-model="inputs[index]"
                  @input="handleInput($event, index)"
                  @keydown.backspace="handleBackspace($event, index)"
                  maxlength="1"
                  type="text"
                  class="input-box"
              />

            </div>
            <button v-if="!timeEnd" class="code-again"><em>{{ formattedTime }}</em> مانده تا دریافت مجدد کد</button>
            <button v-else @click="startTimer" class="code-again2">دریافت مجدد کد</button>
          </div>

          <div class="btns">

            <v-btn @click="login" :loading="loading" class="btn btn-primary">تایید</v-btn>

            <div class="separator"></div>
            <router-link class="btn disposable" to="/auth/password">ورود با رمز عبور <i class="fa fa-angle-left"></i>
            </router-link>

          </div>


        </div>

      </div>
    </div>
    <v-snackbar
        :timeout="3000"
        v-model="snackbar"
        :color="colorSnack"
    >
      {{ textSnack }}

      <template v-slot:actions>
        <v-btn

            variant="tonal"
            @click="snackbar = false"
        >
          بستن
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import store from "@/store";
import Cookies from 'js-cookie';
import axios from "axios";

export default {
  name: "OtpView",
  data() {
    return {
      apiUrl: store.getters.baseUrl,
      loading: false,
      snackbar: false,
      otp: '',
      textSnack: false,
      colorSnack: '',
      inputs: ["", "", "", "", ""],
      timeRemaining: '',
      intervalId: null,
      timeEnd: false,
      mobile: '',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
    }
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.timeRemaining / 60);
      const seconds = this.timeRemaining % 60;
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    },
  },
  methods: {
    login() {
      let body = {
        api: true,
        username: this.mobile,
        otp: this.inputs.join(''),
      }
      axios.post(this.apiUrl + 'login/formpro', body, {headers: this.headers})
          .then(res => {
            if (res.data.result === 1) {
              this.$router.push('/')
            }
            if (res.data.result === 0 && res.data.msg === 'wrong otp') {
              this.showMsg('کد وارد شده صحیح نیست!', 'red')
            }
            if (res.data.result === 1 ) {
             this.setCookie(res.data.token)
            }
          })
    },
    setCookie(token) {
      Cookies.set('token', token, {expires: 7, path: '/'});
    },
    getCookie() {
      return Cookies.get('token');
    },
    deleteCookie() {
      Cookies.remove('token');
    },
    onlyNumber() {
      this.mobile = this.mobile.replace(/[^0-9]/g, '');

    },
    showMsg(msg, color) {
      this.snackbar = true
      this.textSnack = msg
      this.colorSnack = color
    },
    startTimer() {
      this.timeRemaining = 120
      const endTime = localStorage.getItem("timerEndTime");
      this.timeEnd = false

      if (endTime) {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeLeft = parseInt(endTime, 10) - currentTime;
        this.timeRemaining = timeLeft > 0 ? timeLeft : 0;
      } else {
        const timerEndTime = Math.floor(Date.now() / 1000) + this.timeRemaining;
        localStorage.setItem("timerEndTime", timerEndTime);
        this.sendOtp()
      }

      this.intervalId = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
        } else {
          clearInterval(this.intervalId);
          this.onTimerEnd();
        }
      }, 1000);

    },
    sendOtp() {
      this.inputs = ["", "", "", "", ""]
      axios.post(this.apiUrl + 'login/createotpcode', {username: this.mobile}, {headers: this.headers})
          .then(res => {
            console.log(res)

          })


    },
    onTimerEnd() {

      localStorage.removeItem("timerEndTime");
      this.timeEnd = true

    },
    handleInput(event, index) {
      const value = event.target.value;
      if (!/^\d$/.test(value)) {
        this.inputs[index] = "";
        return;
      }
      if (index > 3) {
        this.login()

      }

      if (index < this.inputs.length - 1) {
        this.$nextTick(() => {
          const nextInput = event.target.nextElementSibling;
          if (nextInput) nextInput.focus();
        });
      }

    },
    handleBackspace(event, index) {

      if (!this.inputs[index] && index > 0) {
        this.$nextTick(() => {
          const prevInput = event.target.previousElementSibling;
          if (prevInput) prevInput.focus();
        });
      }

    },
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  mounted() {
    this.mobile = store.getters.getMobile
    if (this.mobile === '') {
      this.$router.push('/auth/login')
    }
    this.startTimer();


  }

}
</script>

<style scoped lang="scss">


.code-again, .code-again2 {
  width: 100%;
  text-align: center;
  color: #344054;
  margin: 10px 0;
  font-size: 12px;
  cursor: default;
}

.code-again2 {
  color: #0A75D8;
  cursor: pointer;
}

.btn {
  width: 100%;
  background: #24272D;
  color: #fff;
  padding: 15px 0;

}

</style>
