<template>
  <div class="main-authentication">
    <div class="login-box box-auth">

      <img src="@/assets/images/logo.png" alt="logo">
      <div class="title">
        <router-link to="/auth/login"><i class="fa-regular fa-arrow-right"></i> بازگشت</router-link>

        <h2 class="w-100">فراموشی رمز عبور</h2>
      </div>

      <div class="input error">
        <label>
          شماره موبایل
        </label>
        <input type="text" placeholder="0912 1234567">
      </div>
      <div class="error-box text-danger">شماره موبایل نامعتبر است.</div>
      <v-btn class="btn btn-save">بازیابی رمز عبور</v-btn>

    </div>
  </div>
</template>

<script>
export default {
  name: "ForgetComponent"
}
</script>

<style scoped>

</style>
