import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from "@/components/auth/Login";
import Password from "@/components/auth/Password";
import Otp from "@/components/auth/Otp";
import forget from "@/components/auth/Forget";
import NewPassword from "@/components/auth/NewPassword";
import Cookies from 'js-cookie';


const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView,
        meta: {requiresAuth: true},
    },

    {
        path: '/admin',
        meta: {requiresAuth: true},
        children: [
            {
                path: 'users',
                name: 'UsersComponent',
                meta: {requiresAuth: true},

                component: () => import('../components/admin/Users')
            },
            {
                path: 'users-role',
                name: 'UsersRole',
                meta: {requiresAuth: true},

                component: () => import('../components/admin/UsersRole')
            },
            {
                path: 'setting',
                name: 'SettingComponent',
                meta: {requiresAuth: true},

                component: () => import('../components/admin/Setting')
            },
            {
                path: 'financial',
                name: 'FinancialComponent',
                meta: {requiresAuth: true},

                component: () => import('../components/admin/Financial')
            },
            {
                path: 'request-status',
                name: 'RequestStatus',
                meta: {requiresAuth: true},

                component: () => import( '../components/admin/RequestStatus')
            },
            {
                path: 'request-types',
                name: 'RequestType',
                meta: {requiresAuth: true},

                component: () => import( '../components/admin/RequestTypes')
            },
            {
                path: 'licences',
                name: 'Licences',
                meta: {requiresAuth: true},

                component: () => import( '../components/admin/Licences')
            },
            {
                path: 'bills/:id?',
                name: 'BillsComponent',
                meta: {requiresAuth: true},

                component: () => import( '../components/admin/Bills')
            },
            {
                path: 'tags',
                name: 'TagsComponent',
                meta: {requiresAuth: true},

                component: () => import( '../components/admin/Tags')
            },
        ]
    },

    {
        path: '/management',
        meta: {requiresAuth: true},
        children: [
            {
                path: 'dashboard',
                meta: {requiresAuth: true},
                name: 'DashboardComponent',
                component: () => import('../components/management/Dashboard'),

            },
            {
                path: 'communication-port',
                meta: {requiresAuth: true},
                name: 'CommunicationPort',
                component: () => import('../components/management/CommunicationPort')
            },
            {
                path: 'popup/new-request',
                meta: {requiresAuth: true},
                name: 'NewRequestView',
                component: () => import('../views/management/NewRequestView')
            },
            {
                path: 'shifts',
                meta: {requiresAuth: true},
                name: 'ShiftManagement',
                component: () => import('../components/management/ShiftManagement')
            },
            {
                path: 'shifts/assignment',
                meta: {requiresAuth: true},
                name: 'ShiftAssignment',
                component: () => import('../components/management/ShiftAssignment')
            },
            {
                path: 'shifts/live-monitoring',
                meta: {requiresAuth: true},
                name: 'LiveMonitoring',
                component: () => import('../components/management/LiveMonitoring')
            },

        ]
    },
    {
        path: '/auth',
        meta: {requiresAuth: false},
        children: [
            {
                path: 'login',
                name: 'LoginComponent',
                component: Login,

            }, {
                path: 'password',
                name: 'PasswordComponent',
                component: Password,
            }, {
                path: 'otp',
                component: Otp,
            }, {
                path: 'forget-password',
                component: forget,
            }, {
                path: 'choice-password',
                component: NewPassword,
            },

        ],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (Cookies.get('token') === undefined) {
            next('/auth/login')
        }

    }
    if (to.matched.some(record => !record.meta.requiresAuth)) {
        if (Cookies.get('token') !== undefined) {
            next('/')
        }

    }
    next()
})
export default router
